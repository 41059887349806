
.App {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#body{
  /* display: flex; */
  justify-content: space-around;
  margin: 0px 0px;
  padding: 10px 0px;
  height: 500px;
  border: 0px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
}
#menu{
  /* width: auto; */
  display: inline;
  max-width: 50%;
  justify-content: space-between;
  float: left;  
  /* border-collapse: separate; */
  max-height: 100%;
  height: 100%;
}

#hoadon{

  border: 0px solid black;
  border-radius: 10px;
  max-width: 50%;
  /* padding: 10px 10px; */
  /* padding-left: 10px; */
  height: 100%;
  max-height: 100%;
  margin:0px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-y: scroll;
}
#nam{
  max-width: 100%;
  border: 0px solid black;
  border-radius: 10px;
  padding: 10px 10px;
  margin:0 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 40%;
  overflow-y:scroll;
  
}
#nu{
  width: auto;
  max-width: 100%;
  border: 0px solid black;
  border-radius: 10px;
  padding: 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 50%;
  overflow-y: scroll;
  margin:10px 10px;
}
#button{
  float: right;
  margin-top:5px;
}
.modalservices{
  border: 1px solid gray;
}

#historytable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#historytable td, #historytable th {
  border: 1px solid #ddd;
  padding: 8px;
  overflow-x: auto;
}

#historytable tr:nth-child(even){background-color: #f2f2f2;}

#historytable tr:hover {background-color: #ddd;}

#historytable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #e2cc8e;
  color: rgb(110, 110, 110);
}
.datefilter{
  display: flex;
  align-items: center;
}
button {
  padding: 0;
border: none;
background: none;
}
#removebutton{
  width: 99%;
  text-align: right;
  
}
.firstline {
  display: flex;
  width: 99%;

}
.firstline > div{
  width: 20px;
}

#monthnyearfilter{
  display: flex;
}
#barchart{
  width: 70%;
  overflow-x: scroll;
}
.detail{
  display: grid;
  grid-template-columns: 40% 30% 30% ;
}

#samedayhistory{
  min-width: 515px;
  margin: 10px 10px;
  padding: 10px 10px;
  height: fit-content;
  border: 0px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.19);  
}
#historytable{
  border: 0px solid black;
  box-shadow: 0 4px 4px 0 rgba(71, 63, 63, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
#inputname{
  border: none;
  height: 20px;
  border-bottom: 2px solid gray;
}
#tablereceipt td, #tablereceipt th {
  border: 1px solid black;
  padding: 8px;

}
#receipt td, #receipt th{
  border: 1px solid gray;
  border-collapse: collapse;
}
#receipt{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}
#tablereceipt th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
#tablereceipt {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
}
@media print
{    
    .no-print, .no-print *{
      display: none !important;}
    div #receipt {
      width: 100%; 
    }
}
@page  
{ 
    size: auto;   /* auto is the initial value */ 
    /* this affects the margin in the printer settings */ 
    /* margin: 10mm 10mm 10mm 10mm;   */
} 
@media screen {
  html, body {
    height: fit-content;
  }
}

  body { 
    /* this affects the margin on the content before sending to printer */ 
    margin: 0px;  
  }

.login{
  text-align: center;
  display: grid;
  width: 35%;
  margin: auto;
  background-color: rgb(145, 109, 63);
  height: 260px;
  border-radius: 10px;
  grid-auto-columns: 100%;
  grid-template-areas: 
  'body';
  grid-template-columns: auto;
}
.loginform{
  width: 100%;
}
.inputlogin{
  width: 250px;
}

.input{
  display: flex;
  justify-content: space-between;
  margin: 30px 20px;
}
.label{
  width: 26%;
  font-weight: bold;
  color: white;
  font-size: 20px;
  text-align: left;
}
#signout{
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
  padding: 10px 0px;
  height: fit-content;
  /* border: 0px solid black; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19); */
  width: auto;
  min-width: 500px;
  /* max-width: 96%; */
}
.one{
  padding: 5px;
  border: 1px solid #ddd;
}
.three{
 display: grid;
 grid-template-columns: auto auto auto auto auto auto auto auto auto auto;

 justify-content: space-between;
}
#expenselist{
  display:flex;
  justify-content: space-between;
}
#checkout{
  display: flex;
  width: auto;
  min-width: fit-content;
}
.labeldate{
  width: 150px;
  
}



/* For desktop: */
.col-3 {width: 65%;display: flex;justify-content: flex-start;align-items: center;}
.col-9 {width: 35%;display: flex;justify-content:space-between ;}
.col-5 {width: 50%;display: flex;}

@media only screen and (max-width:780px)
{
  /* For mobile phones: */
  [class*="col-"]{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  [class*="item1"]{
    width: 30%;
    display: flex;
    justify-content: flex-start;
  }
  [class*="item2"]{
    width: 70%;
    display: flex;
    justify-content: space-between;
  }
  [class*="short"]{
    display: none;
  }
  [class*="three"]{
    grid-template-columns:1fr 1fr 1fr ;
  }
  [class*="detail"]{
    grid-template-columns: 50% 50%;
  }
  
  [class*="inputlogin"]{
    width: 200px;
  }
  [class*="login"]{
    width:100%;
    height: 105%;
  }
  [class*='scroll-to-top']{
    position: fixed;
    bottom: 20px;
    left: 7px;
    z-index: 99;
  }

}
.row header::before{
  content:"";
  clear: both;
  display: flex;
  grid-template-columns: 50% 50%;
}
.row header::after{
  content: "";
  clear: both;
  display: flex;
  justify-content: center;
  grid-auto-columns: 50%;
}
.head::before{
  content:"";
  clear: both;
  display: flex;
  grid-template-columns: 65% 35%;

}
.head::after{
  content: "";
  clear: both;
  display: flex;
  justify-content: center;
  grid-template-columns: 50% 50%;
}
[class*="col-"]{
  float: left;
}


#main{
  display: grid;
  grid-template-areas:
    'header '
    'main '
    'footer ';
  grid-auto-columns: 100%;
  grid-gap: 0.5em;

}
.head{ grid-area:header;}
.row header { grid-area:header;}
#body {grid-area: main;}
#historytable {grid-area: footer;}
.loginform{grid-area: body;}
#mainpage{
  display: grid;
  grid-auto-columns: 100%;
  margin: 0px 0px;
  touch-action: manipulation;
}
.nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
}
.nav > h4{
  margin: 0;
}
.scroll-to-top{
  position: fixed;
  bottom: 20px;
  right: 7px;
  z-index: 99;
}
a:visited {
  color: #8a5337;
}
a:link {
  color: #8a5337;
}
#p2{
  margin: 10px 10px
}
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  transition: 0.4s;
}
.accordionn {
  color: #444;
  cursor: pointer;
  align-items: center;
  height: 30px;
  border: none;
  outline: none;
  transition: 0.4s;
}

.fc .fc-col-header-cell-cushion {
  display: flex;
  padding: 1px 1px;
  width: 35px;
}
.fc-scroller{
  display: flex;
}
.fc-col-header > tbody > tr{
  display: flex;
  justify-content: space-between;
}
.fc-col-header-cell fc-day fc-day-sun{
  flex-grow: 1;
}
.fc-col-header > tbody > tr > th > a{
  flex-grow: 1;
}
.fc-col-header > tbody > tr > th{
  flex-grow: 1;
}
.newinputdec{
  border: 0px;
  background-color: rgb(224, 224, 224);
  cursor: pointer;
  box-sizing: border-box;
  width: 50px;
  height: 25px;
  font-size: 16px;
  border-radius: 5px;
}